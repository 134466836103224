import { FaDiscord } from "react-icons/fa";
import { motion } from "framer-motion";

export default function ButtonDiscord() {
  return (
    <div>
      <a href="https://discord.gg/5ReENHy3fW" target="_blank" rel="noreferrer">
        <AIButton />
      </a>
    </div>
  );
}

const AIButton = () => {
  return (
    <button className="relative text-white font-medium px-3 py-2 rounded-md overflow-hidden transition-transform hover:scale-105 active:scale-95">
      {/* Flex container for icon and text */}
      <div className="flex items-center relative z-10">
        <FaDiscord className="text-2xl mr-2" />
        <span>Rejoins-nous</span>
      </div>

      {/* Background Animation */}
      <motion.div
        initial={{ left: 0 }}
        animate={{ left: "-300%" }}
        transition={{
          repeat: Infinity,
          repeatType: "mirror",
          duration: 4,
          ease: "linear",
        }}
        className="bg-[linear-gradient(to_right,#8f14e6,#e614dc,#e61453,#e68414,#e6e614)] absolute inset-0 w-[400%] h-full z-0"
      ></motion.div>
    </button>
  );
};
