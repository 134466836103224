import { IoGiftOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function PricingContent() {
  return (
    <div className="w-full bg-white p-6 shadow-none lg:w-[250px] lg:shadow-xl">
      <div className="grid grid-cols-2 lg:grid-cols-1">
        <div className="mb-3 space-y-3">
          <div className="flex items-center">
            <IoGiftOutline className="text-xl" />
            <h3 className="font-semibold">Nitro Classic</h3>
          </div>
          <Link to="/#pricing" className="block text-sm hover:underline">
            1 mois
          </Link>
          <Link to="/#pricing" className="block text-sm hover:underline">
            1 an
          </Link>
        </div>
        <div className="mb-6 space-y-3">
          <div className="flex items-center">
            <IoGiftOutline className="text-xl" />
            <h3 className="font-semibold">Nitro</h3>
          </div>
          <Link to="/#pricing" className="block text-sm hover:underline">
            1 mois
          </Link>
          <Link to="/#pricing" className="block text-sm hover:underline">
            1 an
          </Link>
        </div>
      </div>
      <a href="https://discord.gg/5ReENHy3fW" target="_blank" rel="noreferrer">
        <button className="w-full rounded-lg border-2 border-neutral-950 px-4 py-2 font-semibold transition-colors hover:bg-neutral-950 hover:text-white">
          Contact sales
        </button>
      </a>
    </div>
  );
}
