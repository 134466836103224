import { FiArrowRight } from "react-icons/fi";

export default function AboutUs() {
  return (
    <div className="grid h-fit w-full grid-cols-12 shadow-xl lg:h-72 lg:w-[600px] lg:shadow-none xl:w-[750px]">
      <div className="col-span-12 flex flex-col justify-between bg-neutral-950 p-6 lg:col-span-4">
        <div>
          <h2 className="mb-2 text-xl font-semibold text-white">About us</h2>
          <p className="mb-6 max-w-xs text-sm text-neutral-400">
            Nous sommes 2 passionnés par la recherche des meilleures
            opportunités pour offrir à nos clients des prix exceptionnels.
          </p>
        </div>
        <a
          href="https://discord.gg/5ReENHy3fW"
          target="_blank"
          rel="noreferrer"
          className="flex items-center gap-1 text-xs text-indigo-300 hover:underline"
        >
          Learn more <FiArrowRight />
        </a>
      </div>
      <div className="col-span-12 grid grid-cols-2 grid-rows-2 gap-3 bg-white p-6 lg:col-span-8">
        <div className="rounded border-2 border-neutral-200 bg-white p-3 transition-colors">
          <h3 className="mb-1 font-semibold">Foxeek</h3>
          <p className="text-xs">Developpeur et business manager</p>
        </div>
        <div className="rounded border-2 border-neutral-200 bg-white p-3 transition-colors">
          <h3 className="mb-1 font-semibold">Vrizz</h3>
          <p className="text-xs">business manager</p>
        </div>
      </div>
    </div>
  );
}
