import ButtonDiscord from "../components/buttonDiscord";

export default function Footer() {
  return (
    <footer className="relative bg-black text-white py-8 overflow-hidden">
      {/* Top Left Gradient Blur for md and above */}
      <span className="absolute top-0 left-[42%] md:left-0 z-0 h-[200px] w-[300px] md:h-[500px] md:w-[600px] -translate-x-[30%] -translate-y-[30%] rounded-full bg-gradient-to-r from-violet-600/20 to-indigo-600/20 blur-3xl hidden md:block" />

      {/* Top Right Gradient Blur for md and above */}
      <span className="absolute top-0 right-[42%] md:top-0 md:right-0 z-0 h-[200px] w-[300px] md:h-[500px] md:w-[600px] translate-x-[30%] -translate-y-[30%] rounded-full bg-gradient-to-r from-orange-600/20 to-amber-600/20 blur-3xl hidden md:block" />

      {/* Centered Blurs for mobile */}
      <span className="absolute top-0 left-1/2 z-0 h-[100px] w-[1000px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-gradient-to-b from-violet-600/20 to-indigo-600/20 blur-3xl md:hidden" />

      <span className="absolute bottom-0 left-1/2 z-0 h-[100px] w-[1000px] -translate-x-1/2 translate-y-1/2 rounded-full bg-gradient-to-b from-orange-600/20 to-amber-600/20 blur-3xl md:hidden" />

      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0 relative z-10">
        {/* Left Section */}
        <div className="text-center md:text-left">
          <h3 className="text-lg font-semibold">Nitro Mania</h3>
          <p className="text-sm mt-2">
            Made By Logan with <span className="text-pink-400">❤️</span>
          </p>
          <p className="text-xs text-gray-300 mt-4">
            © 2024 Nitro Mania. All rights reserved.
          </p>
        </div>

        {/* Right Section - Discord Button */}
        <div>
          <ButtonDiscord />
        </div>
      </div>
    </footer>
  );
}
