export const QUESTIONS = [
  {
    question: "Comment vous procurez-vous les nitros ?",
    answer:
      "Nous avons accès à des sources fiables qui nous permettent de proposer des nitros à un prix compétitif, tout en garantissant que toutes nos transactions sont entièrement légales.",
  },
  {
    question: "Comment puis-je être sûr que les nitros sont sécurisés ?",
    answer:
      "Nous achetons les nitros juste après votre transaction d'où le délai de livraison qui peut prendre quelques minutes/heures. Nous garantissons que tous les nitros que nous vendons sont acquis de manière légale et qu'ils resteront actifs sur votre compte sans risque de suppression ou de bannissement.",
  },
  {
    question: "Où acheter les nitros ?",
    answer:
      "Les nitros peuvent être achetés via notre discord pour ne pas avoir de soucis sur les nitros ainsi qu'une livraison sur.",
  },
];
