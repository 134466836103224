import Hero from "../components/Hero";
import Pricing from "../components/Pricing";
import FAQ from "../components/FAQ";

export default function Home() {
  return (
    <div className="page page-home">
      <Hero />
      <Pricing />
      <FAQ />
    </div>
  );
}
